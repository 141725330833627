import Modal, { ModalVariant } from 'src/components/organisms/Modal';

export interface ConfirmationModalProps {
  confirmationAction: () => void;
  showConfirmationModal: boolean;
  setShowConfirmationModal: (show: boolean) => void;
  modalTitle?: string;
  confirmButtonLabel?: string;
  modalText?: string;
  cancelButtonLabel?: string;
}

const ConfirmationModal = ({
  confirmationAction,
  showConfirmationModal,
  setShowConfirmationModal,
  modalTitle = 'Confirmation',
  confirmButtonLabel = 'Confirm',
  modalText = 'Are you sure you want to proceed?',
  cancelButtonLabel = 'Cancel'
}: ConfirmationModalProps) => {
  return (
    <Modal
      title={modalTitle}
      labelButtonConfirm={confirmButtonLabel}
      text={modalText}
      showModal={showConfirmationModal}
      onConfirm={async () => {
        setShowConfirmationModal(false);

        await confirmationAction();
      }}
      onCancel={() => setShowConfirmationModal(false)}
      variant={ModalVariant.none}
      labelButtonCancel={cancelButtonLabel}
    />
  );
};

export default ConfirmationModal;
