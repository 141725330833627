import { IncomeCalculationDocument } from '@snapptinc/fraud-platform';
import { IncomeVerificationEnum } from 'src/ts/enums/session';
import { IncomeVerification } from '../../ts/interfaces/incomeVerification';
import { defaultStateIVFields, eligibleDocuments } from './constants';

/**
 * Converts the shape of the income verification document object from the shape the API returns
 * to the shape the client expects.
 *
 * @param {IncomeCalculationDocument} objectToConvert
 * @returns {IncomeVerification}
 */
export function convertToIncomeVerificationType(
  objectToConvert: IncomeCalculationDocument
): IncomeVerification {
  const incomingObject: IncomeCalculationDocument = { ...objectToConvert };
  // This map helps with the conversion because we can't check the type keys at runtime...
  const mapFromApiToClient: Record<keyof IncomeCalculationDocument, keyof IncomeVerification> = {
    applicant_name: 'recipientName',
    document_end_date: 'payPeriodEndDate',
    document_id: 'id',
    document_start_date: 'payPeriodStartDate',
    gross_income: 'grossPay',
    income_source: 'incomeSource'
  };
  const result = {};

  for (const key in mapFromApiToClient) {
    const castKey = key as keyof IncomeCalculationDocument;
    if (mapFromApiToClient[castKey]) {
      const mappedKey = mapFromApiToClient[castKey];
      Object.assign(result, {
        [mappedKey]: incomingObject[castKey]
      });
    }
  }
  return result as IncomeVerification;
}

export const getIncomeDocumentsFromSession = (
  incomeVerificationReviewId: string
): IncomeVerification[] => {
  const documentsFromSession = window.sessionStorage.getItem(
    `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`
  );
  return documentsFromSession === null ? [] : JSON.parse(documentsFromSession);
};

export const parseIncomeCalculationDocuments = (documents: IncomeVerification[]) =>
  documents.map((document) => ({
    document_id: document.id,
    applicant_name: document.recipientName || '',
    document_start_date: document.payPeriodStartDate || '',
    document_end_date: document.payPeriodEndDate || '',
    gross_income: parseFloat(
      document.grossPay == null || document.grossPay === ''
        ? '0'
        : document.grossPay.replace(/[$,]/g, '')
    ),
    income_source: document.incomeSource || ''
  }));

export const getIsIVDocumentEmpty = (document: IncomeVerification): boolean => {
  return (
    eligibleDocuments.includes(document.type) &&
    Object.keys(defaultStateIVFields).every((key) => !document[key as keyof IncomeVerification])
  );
};

export const clearIVFormFields = (
  setFormValue: (key: keyof IncomeVerification, value: string) => void
) => {
  Object.entries(defaultStateIVFields).forEach(([key, value]) => {
    setFormValue(key as keyof IncomeVerification, value);
  });
};
