import { Fetch } from 'src/adapters';
import { userApi } from 'src/api';
import { default as authApi } from 'src/features/auth/api';
import { IUser, IUserCompanyRequest, IUserRequest, IUserResponse } from 'src/ts/interfaces';

const api = {
  updateMyUser: async (payload: IUserRequest) => {
    const currentUser = await authApi.fetchCurrentUser();
    const { user } = payload;
    const userRequest: IUserRequest = { user: { ...currentUser, ...user } };

    const response = await userApi.updateMyUser(userRequest);
    return { ...(response.data || {}) } as IUserResponse;
  },

  createUserAndCompany: async (payload: IUserCompanyRequest) => {
    const response = await userApi.createUserAndCompany(payload);
    return { ...(response.data || {}) } as IUser;
  },

  resetPassword: async (): Promise<Response> => {
    const { email } = await authApi.fetchCurrentUser();
    //TBD: this should be converted into a method in user_controller and be included in SDK
    const response = Fetch.post(`forgot_password`, { email });
    return response;
  },

  resetUserPassword: async (email: string): Promise<Response> => {
    const response = Fetch.post(`forgot_password`, { email });
    return response;
  }
};

export default api;
