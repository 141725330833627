import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  eligibleDocuments,
  selectors as incomeVerificationSelectors
} from 'src/features/incomeVerification';
import {
  getIncomeDocumentsFromSession,
  getIsIVDocumentEmpty
} from 'src/features/incomeVerification/utils';
import { IncomeVerification } from 'src/ts/interfaces/incomeVerification';
import { ErrorWarningMessage, IncomeDataSummary } from '../styles';

const CalculationHeader = () => {
  const { incomeVerificationReviewId } = useParams();

  const calculateIncomeResult = useSelector(incomeVerificationSelectors.selectCalculateIncomeData);

  const documents: IncomeVerification[] = getIncomeDocumentsFromSession(
    incomeVerificationReviewId as string
  );

  const isEveryDocumentIVIneligible = documents.every(
    (document) => !eligibleDocuments.includes(document.type)
  );

  const isEligibleDocumentEmpty = documents.some(getIsIVDocumentEmpty);

  return (
    <IncomeDataSummary>
      <h2>Income Data Summary</h2>
      {isEligibleDocumentEmpty && (
        <ErrorWarningMessage status="warning">
          One or more IV eligible documents were left blank. Verify the document type and all
          information for these documents are correct prior to sending the report.
        </ErrorWarningMessage>
      )}
      {calculateIncomeResult?.reasons.map((reason, i) => (
        <ErrorWarningMessage status={reason.type} key={`${reason.type}-${i}`}>
          {reason.message}
        </ErrorWarningMessage>
      ))}
      {isEveryDocumentIVIneligible && (
        <ErrorWarningMessage status="error">
          All documents in this submission are ineligible for income verification review.
        </ErrorWarningMessage>
      )}
      {(isEveryDocumentIVIneligible ||
        (calculateIncomeResult?.reasons && calculateIncomeResult?.reasons.length > 0)) && (
        <ErrorWarningMessage
          status={
            calculateIncomeResult?.reasons.every((reason) => reason.type === 'warning')
              ? 'warning'
              : 'error'
          }
        >
          Verify the data summary is correct before sending the report.
        </ErrorWarningMessage>
      )}
    </IncomeDataSummary>
  );
};

export default CalculationHeader;
