import { FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { configVars, downloadUrlsToZip } from 'src/helpers';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { FeatureFlagKey } from 'src/ts/enums';
import { StyledButton } from './styles';

export interface ButtonDownloadFilesProps {
  applicantId?: string;
  urls: string[];
  fileName: string;
  isDisabled: boolean;
  className?: string;
}

const ButtonDownloadFiles: FC<ButtonDownloadFilesProps> = (props) => {
  const { isDisabled, fileName, urls, className, applicantId } = props;
  const { execute: downloadZip, loading: isLoading } = useAsyncCallback(downloadUrlsToZip);

  const epaDocumentDownload = useFeatureFlag(FeatureFlagKey.EPA_DOCUMENT_DOWNLOAD);

  const onClick = () => {
    if (isDisabled) {
      return;
    }
    if (epaDocumentDownload) {
      window.location.href = `${configVars.epa_url}/applicants/${applicantId}/documents.zip`;
    } else {
      downloadZip(urls, fileName);
    }
  };

  return (
    <StyledButton
      name={`download_files_${fileName}_button`}
      isDisabled={isLoading || isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      variant={ButtonVariant.ghost}
      disabledStyle={ButtonDisabledStyle.transparent}
      className={className}
    >
      {!isLoading && <Icon icon="download-icon" color="blue700" />}
    </StyledButton>
  );
};

export default ButtonDownloadFiles;
