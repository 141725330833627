// Vendor
import { FC } from 'react';

// Components
import Table from 'src/components/atoms/Table';
import TableBodyCollapsable from 'src/components/atoms/TableBodyCollapsable';
import TableCell from 'src/components/atoms/TableCell';
import TableContainer from 'src/components/atoms/TableContainer';
import TableHead from 'src/components/atoms/TableHead';
import TableRow from 'src/components/atoms/TableRow';
import CollapsibleTableRow from './components/CollapsibleTableRow';

// Constants
import { columns } from './constants';

// Types
import { ProofListTableProps } from './types';

//Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import DocumentReviewTableRow from './components/DocumentReviewTableRow';

const ProofListTable: FC<ProofListTableProps> = ({
  entry,
  proofs,
  documentReviews,
  onCheckbox,
  onDownload,
  onTextFields,
  onVisibility,
  onSaveNote
}) => {
  const { isAccountRepresentative } = useUserRoles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ label, width, textAlign }, index) => (
              <TableCell width={width} key={index} textAlign={textAlign}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBodyCollapsable>
          {proofs?.map((proof) => (
            <CollapsibleTableRow
              key={proof.id}
              entry={entry}
              proof={proof}
              onCheckbox={onCheckbox}
              onDownload={onDownload}
              onTextFields={onTextFields}
              onVisibility={onVisibility}
              onSaveNote={onSaveNote}
              isAccountRepresentative={isAccountRepresentative ?? false}
            />
          ))}
          {documentReviews?.map((documentReview) => (
            <DocumentReviewTableRow
              key={documentReview.id}
              documentReview={documentReview}
              onDownload={onDownload}
            />
          ))}
        </TableBodyCollapsable>
      </Table>
    </TableContainer>
  );
};

export default ProofListTable;
