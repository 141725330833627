import { resetUserPasswordThunk } from 'src/features/user';
import { useAppDispatch } from 'src/store';
import { ConfirmationModalProps } from './ConfirmationModal';
import { ConfirmationModal } from './index';

interface ModalProps
  extends Pick<ConfirmationModalProps, 'showConfirmationModal' | 'setShowConfirmationModal'> {
  userEmail: string;
}

const ConfirmResetPasswordModal = ({
  userEmail,
  showConfirmationModal,
  setShowConfirmationModal,
  ...props
}: ModalProps) => {
  const dispatch = useAppDispatch();

  const handleResetPasswordClick = () => dispatch(resetUserPasswordThunk(userEmail));

  return (
    <ConfirmationModal
      confirmationAction={handleResetPasswordClick}
      showConfirmationModal={showConfirmationModal}
      setShowConfirmationModal={setShowConfirmationModal}
      modalTitle=""
      confirmButtonLabel="Yes, continue"
      cancelButtonLabel="Go back"
      modalText="This action will send a password reset link to the user's email. Are you sure you want to continue?"
      {...props}
    />
  );
};

export default ConfirmResetPasswordModal;
