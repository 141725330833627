// Vendor
import { differenceInDays, format, formatDistanceToNow } from 'date-fns';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { configVars } from 'src/helpers';

// Components
import Button, {
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import {
  ButtonLinkColor,
  ButtonLinkSize,
  ButtonLinkVariant
} from 'src/components/atoms/ButtonLink';
import { Icon } from 'src/components/atoms/Icon';
import Table from 'src/components/atoms/Table';
import TableBody from 'src/components/atoms/TableBody';
import TableCell from 'src/components/atoms/TableCell';
import TableContainer from 'src/components/atoms/TableContainer';
import TableHead from 'src/components/atoms/TableHead';
import TableRow from 'src/components/atoms/TableRow';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import ButtonDownloadFiles from '../ButtonDownloadFiles';
import {
  Content,
  Header,
  HeaderInfo,
  MonthlyGrossIncomeCell,
  ProofCount,
  StatusLabelCell,
  StyledButton,
  StyledIcon,
  StyledTag,
  StyledText
} from './styles';

// Helpers
import { transformResultToReadable } from 'src/features/fdeInbox/helpers';
import { cleanFileName } from 'src/helpers';
import { currencyFormat } from 'src/helpers/currencyFormat';

// Enums
import { EntryStatusEnum, FeatureFlagKey, Roles } from 'src/ts/enums';

// Types
import {
  EntryStatusType,
  IFDEInboxEntry,
  IFDEInboxEntryEntries,
  RoleType
} from 'src/ts/interfaces';

// Constants
import { columns, oldColumns } from './constants';

// Hooks
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import { useFeatureFlag } from 'src/hooks';
import { Feature } from 'src/ts/enums/feature';

export interface FolderRowProps {
  showReviewButton: boolean;
  folder: IFDEInboxEntry | null;
  role: RoleType;
}

const parseSubmissionTimeDistance = (date: string) => {
  const submissionTime = new Date(date);
  // If date hasn't passed 1 day or more, use formatDistance utility
  // otherwise use simple format
  if (differenceInDays(submissionTime, new Date()) === 0) {
    const distance = formatDistanceToNow(submissionTime, {
      addSuffix: true
    });
    return `${distance.charAt(0).toUpperCase()}${distance.substring(1)}`;
  }

  return format(submissionTime, 'MM/dd/yyyy');
};

const BasicInfo = ({
  name,
  company,
  showReviewButton,
  folder_id
}: {
  name: string;
  company: string;
  showReviewButton: boolean;
  folder_id: string;
}) => {
  return (
    <Header>
      <HeaderInfo>
        <Icon icon="person-icon" color="gray900" />
        <StyledText variant={TextVariant.h4} color={TextColor.gray700}>
          {name}
        </StyledText>
      </HeaderInfo>
      <HeaderInfo>
        <Icon icon="company-model-icon" color="gray700" />
        <StyledText testId="property-name" variant={TextVariant.h4} color={TextColor.gray700}>
          {company}
        </StyledText>
      </HeaderInfo>
      <HeaderInfo>
        {showReviewButton && (
          <StyledButton
            to={`/folder/${folder_id}`}
            label="review_button"
            name="review_button"
            variant={ButtonLinkVariant.outline}
            size={ButtonLinkSize.medium}
            color={ButtonLinkColor.primary}
          >
            Review <StyledIcon icon="icon-arrow-right" color="primary" />
          </StyledButton>
        )}
      </HeaderInfo>
    </Header>
  );
};

const MonthlyGrossIncomeColumnHeader = ({
  incomeVerificationEnabledProperty,
  label,
  idx,
  width
}: {
  incomeVerificationEnabledProperty: boolean;
  label: string;
  idx: number;
  width: number;
}) => {
  const { isLeasingTeam } = useUserRoles();

  const showIncomeVerificationTooltip = !incomeVerificationEnabledProperty && isLeasingTeam;
  return (
    <TableCell key={idx} width={width} name="monthly-gross-income-column">
      {label}
      {showIncomeVerificationTooltip && (
        <ToolTip
          direction={ToolTipDirection.bottom_fit}
          content="Want your applicant's calculated income? Reach out to your Snappt contact for more information."
          className="monthly-gross-income-tooltip"
        >
          <Icon icon="help" />
        </ToolTip>
      )}
    </TableCell>
  );
};

const FolderRow: FC<FolderRowProps> = (props) => {
  const { showReviewButton, folder, role } = props;
  const navigate = useNavigate();

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  const monthlyGrossIncomeFdeInboxColumnEnabled = useFeatureFlag(
    FeatureFlagKey.MONTHLY_GROSS_INCOME_FDE_INBOX_COLUMN
  );

  const incomeVerificationEnabledProperty =
    folder?.property_features?.some(
      (propertyFeature) =>
        propertyFeature.feature_code === Feature.INCOME_VERIFICATION &&
        propertyFeature.state === 'enabled'
    ) ?? false;

  const showNewColumns =
    incomeVerificationEnabledProperty ||
    (!incomeVerificationEnabledProperty && monthlyGrossIncomeFdeInboxColumnEnabled);

  const fdeColumns = showNewColumns ? columns : oldColumns;

  const checkIsEnabled = (status: EntryStatusType) => {
    if (
      role &&
      [
        Roles.Admin,
        Roles.Reviewer,
        Roles.FraudAnalyst,
        Roles.SeniorFraudAnalyst,
        Roles.FdeManager,
        Roles.AccountRepresentative
      ].includes(role as Roles)
    ) {
      return [EntryStatusEnum.Pending, EntryStatusEnum.Ready, EntryStatusEnum.Viewed].includes(
        status
      );
    }

    return [EntryStatusEnum.Ready, EntryStatusEnum.Viewed].includes(status);
  };

  const showDownload = () => {
    return role !== Roles.OnSiteRepresentative;
  };

  const hideViewColumn = () => {
    const allEntriesPending = folder?.entries?.every(
      (entry) => entry.status === EntryStatusEnum.Pending
    );
    return incomeVerificationEnabledProperty && allEntriesPending;
  };

  const onReportButtonClick = useCallback(
    (row: IFDEInboxEntryEntries) => {
      if (incomeVerificationEnabledProperty) {
        window.open(
          `${configVars.epa_url}/applicants/${row.applicant_id}/report?format=html&preset=summary`,
          '_blank'
        );
      } else {
        navigate(`/application/${row.id}/report`);
      }
    },
    [navigate, incomeVerificationEnabledProperty]
  );

  return (
    <Content>
      <BasicInfo
        folder_id={folder?.folder_id as string}
        name={folder?.folder_name as string}
        company={folder?.property_name as string}
        showReviewButton={showReviewButton}
      />

      <TableContainer className="fde-table">
        <Table>
          <TableHead>
            <TableRow>
              {fdeColumns.map(({ label, width, name }, idx) => {
                if (hideViewColumn() && name === 'report') return null;
                if (!showDownload() && name === 'doc_download') return null;
                if (name === 'monthly_gross_income') {
                  return (
                    <MonthlyGrossIncomeColumnHeader
                      incomeVerificationEnabledProperty={incomeVerificationEnabledProperty}
                      key={idx}
                      label={label}
                      idx={idx}
                      width={width}
                    ></MonthlyGrossIncomeColumnHeader>
                  );
                }

                return (
                  <TableCell key={idx} width={width}>
                    {label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {folder?.entries?.map((row: IFDEInboxEntryEntries) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.unit || 'Not Specified'}</TableCell>
                  <TableCell>
                    {parseSubmissionTimeDistance(row.submission_time as string)}
                  </TableCell>
                  <StatusLabelCell status={row.status as EntryStatusType}>
                    {transformResultToReadable(row.status)}
                  </StatusLabelCell>
                  {!hideViewColumn() && (
                    <TableCell>
                      <Button
                        isDisabled={!checkIsEnabled(row.status as EntryStatusType)}
                        onClick={() => onReportButtonClick(row)}
                        name="view_report_button"
                        variant={ButtonVariant.ghost}
                        disabledStyle={ButtonDisabledStyle.transparent}
                        size={ButtonSize.normal}
                      >
                        <Icon icon="icon-search" color="blue700" />
                      </Button>
                    </TableCell>
                  )}

                  <TableCell>
                    <StyledTag
                      label={row.result}
                      cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
                    />
                  </TableCell>
                  {!showNewColumns && (
                    <>
                      <TableCell>{row.proof_count}</TableCell>
                      {showDownload() && (
                        <TableCell>
                          <ButtonDownloadFiles
                            applicantId={row.applicant_id}
                            isDisabled={!row.proof_count}
                            urls={row.proof_urls as string[]}
                            fileName={`${cleanFileName(row.applicant_full_name)}_Document`}
                          />
                        </TableCell>
                      )}
                    </>
                  )}

                  {showNewColumns && (
                    <>
                      {row.income_calculation?.state === 'Available' ? (
                        <TableCell>{currencyFormat(row.income_calculation?.value || 0)}</TableCell>
                      ) : (
                        <TableCell>
                          <MonthlyGrossIncomeCell>
                            {row.income_calculation?.value
                              ? currencyFormat(row.income_calculation?.value || 0)
                              : row.income_calculation?.state || 'Not Available'}
                          </MonthlyGrossIncomeCell>
                        </TableCell>
                      )}
                      <TableCell>
                        <ProofCount>{row.proof_count}</ProofCount>
                        {showDownload() && (
                          <ButtonDownloadFiles
                            className="align-download-button"
                            applicantId={row.applicant_id}
                            isDisabled={!row.proof_count}
                            urls={row.proof_urls as string[]}
                            fileName={`${cleanFileName(row.applicant_full_name)}_Document`}
                          />
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  );
};

export default FolderRow;
