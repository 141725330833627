export const NAME = 'incomeVerification';
export const INCOME_CALCULATION_PATH = `${NAME}/calculateIncome`;
export const SEND_REPORT_PATH = `${NAME}/sendReport`;
export const DISPUTE_PATH = `${NAME}/dispute`;

type ErrorWarningMapping = {
  type: 'warning' | 'error';
  message: string;
};

export const ERROR_WARNING_MAPPINGS: Record<number, ErrorWarningMapping> = {
  2000: {
    type: 'warning',
    message: 'Gross pay is missing from one or more documents.'
  },
  2006: {
    type: 'warning',
    message: 'The pay start and/or end dates are missing from one or more documents.'
  },
  2008: {
    type: 'warning',
    message: 'Income Source is missing from one or more documents.'
  },
  2009: {
    type: 'warning',
    message: 'One or more documents within this submission are older than 90 days.'
  },
  2010: {
    type: 'warning',
    message: 'Document has been excluded from the total income calculation amount.'
  },
  2011: {
    type: 'warning',
    message:
      'One or more of the provided income sources was a duplicate and not included in the total income amount.'
  },
  3001: {
    type: 'error',
    message: 'Gross pay is missing from all documents.'
  },
  3002: {
    type: 'error',
    message: 'Recipient Name is missing from one or more documents.'
  },
  3003: {
    type: 'error',
    message: 'The recipient name does not match the applicant name associated with this submission.'
  },
  3004: {
    type: 'error',
    message: 'Documents do not span a minimum of 28 consecutive days.'
  },
  3005: {
    type: 'error',
    message:
      'The documents within this submission do not represent pay issued within the past 90 days.'
  },
  3007: {
    type: 'error',
    message: 'The pay start and/or end dates are missing from all documents.'
  },
  3008: {
    type: 'error',
    message: 'One or more documents within this submission are older than 90 days.'
  }
};

export const eligibleDocuments = [
  'PAYSTUB',
  'DEPARTMENT_OF_VETERANS_AFFAIRS_BENEFIT_LETTER',
  'SOCIAL_SECURITY_BENEFITS_LETTER',
  'SOCIAL_SECURITY_STATEMENT'
];

export const defaultStateIVFields = {
  payPeriodStartDate: '',
  payPeriodEndDate: '',
  payPeriodDateRange: '',
  recipientName: '',
  grossPay: '',
  incomeSource: ''
};
