import { envVars } from 'src/constants/config';
import { IConstants } from 'src/ts/interfaces';

/**
 * Overrides URL constants such that they're relative to the domain we're running at.
 * @param constants The IConstants object ot override.
 * @param host The hostname we're running at.
 * @returns An IConstants object relative to the current host.
 */
export const overrideUrls = (constants: IConstants, host: string): IConstants => {
  // If its our standard domains, just return the constants immediately
  // Otherwise, proceed with overrides
  if (/(snappt\.com)|(documentportal.info)/.test(host)) return constants;

  // Handle envs where structuredClone isn't available (e.g. when running tests)
  const g = typeof window !== 'undefined' ? window : global;
  const cloneMethod =
    g.structuredClone !== undefined
      ? g.structuredClone
      : (c: IConstants) => JSON.parse(JSON.stringify(c));

  // Clone and override
  const clone = cloneMethod(constants);
  const urlKeys = ['server_url', 'frontend_url', 'api_url', 'epa_url', 'feature_flag_api_url'];
  const hostParts = host.split('.');
  // Get just the TLD (e.g. wintheknifefight.com)
  const hostBase = hostParts.slice(hostParts.length - 2, hostParts.length).join('.');
  urlKeys.forEach((k) => {
    clone[k] = clone[k].replace('snappt.com', hostBase);
  });
  return clone;
};

export const getConfigVars = (hostname: string): IConstants => {
  switch (hostname) {
    case 'wintheknifefight':
      return envVars.wintheknifefightDev;
    case 'fraud.snappt.com':
    case 'documentportal.info':
    case 'client.snappt.com':
      return envVars.production;
    case 'fraud-sandbox.snappt.com':
    case 'sandbox.documentportal.info':
      return envVars.sandbox;
    case 'fraud-ralph.snappt.com':
    case 'ralph.documentportal.info':
      return envVars.ralph;
    case 'fraud-demo.snappt.com':
    case 'demo.documentportal.info':
      return envVars.demo;
    case 'dev.documentportal.info':
    case 'fraud.dev.snappt.com':
    case 'fraud.dev.wintheknifefight.com':
      return overrideUrls(envVars.dev, hostname);
    case 'test.documentportal.info':
    case 'fraud.test.snappt.com':
    case 'fraud.test.wintheknifefight.com':
      return overrideUrls(envVars.test, hostname);
    case 'uat.documentportal.info':
    case 'fraud.uat.snappt.com':
    case 'fraud.uat.wintheknifefight.com':
      return overrideUrls(envVars.uat, hostname);
    case 'fraud.demo.snappt.com':
    case 'fraud.demo.wintheknifefight.com':
      return overrideUrls(envVars.newDemo, hostname);
    // Non-overlapping prod domains only. Must update base config after cutover.
    case 'fraud.wintheknifefight.com':
    case 'fraud.prod.wintheknifefight.com':
    case 'fraud.prod.snappt.com':
      return overrideUrls(envVars.prod, hostname);
    default:
      return envVars.development;
  }
};

// Handle test environments where window might not be available
const hostname = typeof window !== 'undefined' ? window.location.hostname : 'development';

export default getConfigVars(hostname);
