import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { FeatureFlagKey } from 'src/ts/enums/featureFlag';
import { useFeatureFlag } from '../useFeatureFlag';

const HEAP_PROPERTY_CHAR_LIMIT = 1024;

export const useHeap = () => {
  const heapIdentityFlagEnabled = useFeatureFlag(FeatureFlagKey.HEAP_IDENTITY);
  const identityVerificationEnabled = useSelector(authSelectors.identityVerificationEnabled);
  const userData = useSelector(authSelectors.user);

  useEffect(() => {
    if (heapIdentityFlagEnabled && userData?.id && window.heap) {
      const { id, role, email, first_name, last_name, properties, company_id } = userData;

      window.heap.identify(id);

      const property_features_enabled = [...userData.property_features_enabled];
      if (identityVerificationEnabled) property_features_enabled.push('identity_verification');

      window.heap.addUserProperties({
        role,
        email,
        first_name,
        last_name,
        company_id: company_id || 'None',
        property_features_enabled:
          property_features_enabled.join(',').slice(0, HEAP_PROPERTY_CHAR_LIMIT) || 'None',
        property_ids:
          properties
            .map((p) => p.short_id)
            .join(',')
            .slice(0, HEAP_PROPERTY_CHAR_LIMIT) || 'None',
        property_names:
          properties
            .map((p) => p.name)
            .join(',')
            .replaceAll(' ', '')
            .slice(0, HEAP_PROPERTY_CHAR_LIMIT) || 'None'
      });
      // Clear event properties, so event properties from the our other apps using Heap don't get attached to the events here
      window.heap.clearEventProperties();
    }
  }, [userData, heapIdentityFlagEnabled, identityVerificationEnabled]);
};
