import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ButtonRow } from 'src/components/molecules/Carousel/styles';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import {
  actions,
  calculateIncomeThunk,
  eligibleDocuments,
  selectors as incomeVerificationSelectors
} from 'src/features/incomeVerification';
import {
  getIncomeDocumentsFromSession,
  getIsIVDocumentEmpty,
  parseIncomeCalculationDocuments
} from 'src/features/incomeVerification/utils';
import { useFeatureFlag } from 'src/hooks';
import IncomeCalculationTileForm from 'src/pages/IncomeCalculationPage/components/IncomeCalculationTileForm/IncomeCalculationTileForm';
import { FeatureFlagKey } from 'src/ts/enums';
import { IncomeVerificationEnum } from 'src/ts/enums/session';
import { IncomeVerification } from 'src/ts/interfaces/incomeVerification';
import CalculationHeader from './components/CalculationHeader';
import SendReportModal from './components/SendReportModal';
import { Content, IncomeResult, IncomeVerificationTiles } from './styles';

const IncomeCalculationPage = () => {
  const { entryId, incomeVerificationReviewId } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAccountRepresentative } = useUserRoles();
  const calculateIncomeIsLoading = useSelector(incomeVerificationSelectors.isLoading);
  const calculateIncomeResult = useSelector(incomeVerificationSelectors.selectCalculateIncomeData);
  const [showSendReportModal, setShowSendReportModal] = useState(false);
  const redirectPath = searchParams.get('redirectPath');

  const isDocumentTypeDropdownActive = useFeatureFlag(
    FeatureFlagKey.DOCUMENT_TYPE_IV_FLOW_DROPDOWN
  );

  const documents: IncomeVerification[] = getIncomeDocumentsFromSession(
    incomeVerificationReviewId as string
  );

  const calculateIncome = useDebouncedCallback(() => {
    const documents = getIncomeDocumentsFromSession(incomeVerificationReviewId as string);
    if (incomeVerificationReviewId && documents && !calculateIncomeIsLoading) {
      const ivDocuments = documents.filter((document) => {
        if (!isDocumentTypeDropdownActive) {
          return true;
        }

        return eligibleDocuments.includes(document.type) && !getIsIVDocumentEmpty(document);
      });

      if (ivDocuments.length) {
        const incomeDocuments = parseIncomeCalculationDocuments(ivDocuments);

        dispatch(
          calculateIncomeThunk({
            submissionId: incomeVerificationReviewId,
            documents: incomeDocuments
          })
        );
      } else {
        dispatch(actions.resetIncomeVerification());
      }
    }
  }, 500);

  useEffect(() => {
    calculateIncome();
  }, []);

  const handleOnSave = (data: IncomeVerification) => {
    documents[data.index] = data;
    window.sessionStorage.setItem(
      `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`,
      JSON.stringify(documents)
    );
    calculateIncome();
  };

  const onSendReport = () => {
    setShowSendReportModal(true);
  };

  const previousClicked = () => {
    window.sessionStorage.setItem(
      `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS_DISPLAY_LAST_PAGE}${incomeVerificationReviewId}`,
      'true'
    );
    navigate(-1);
  };

  return (
    <>
      <Content>
        <CalculationHeader />
        <IncomeVerificationTiles>
          <Row columns={3} wrap="wrap" justify={'space-between'}>
            {documents.map((document: IncomeVerification) => {
              if (document.index > -1) {
                return (
                  <Row.Col size={1} key={document.docId}>
                    <IncomeCalculationTileForm
                      reason_codes={calculateIncomeResult?.reason_codes ?? []}
                      document={document}
                      onSave={handleOnSave}
                    />
                  </Row.Col>
                );
              }
              return null;
            })}
          </Row>
          <IncomeResult>
            {calculateIncomeResult?.status === 'error' ? (
              'Monthly Income Not Available'
            ) : (
              <>
                Monthly Income:&nbsp;{calculateIncomeIsLoading && <span>calculating...</span>}
                {!calculateIncomeIsLoading && calculateIncomeResult?.data && (
                  <span>${calculateIncomeResult?.data}</span>
                )}
              </>
            )}
          </IncomeResult>
        </IncomeVerificationTiles>
        <Gap height={2} />
        <Row>
          <Row.Col size={1} alignContent="center">
            <Row justify="center">
              {!isAccountRepresentative && (
                <Button
                  name="button-send-report"
                  onClick={onSendReport}
                  size={ButtonSize.normal}
                  color={ButtonColor.primary}
                  variant={ButtonVariant.contained}
                  isDisabled={calculateIncomeIsLoading}
                >
                  Send Report
                </Button>
              )}
            </Row>
          </Row.Col>
        </Row>
        <Gap height={5} />
        <ButtonRow>
          <Row.Col size={3}>
            <Button
              name="button-back-to-income-verification-flow"
              variant={ButtonVariant.outline}
              className="income-verification-buttons"
              onClick={previousClicked}
            >
              <Icon icon="left-arrow" />
            </Button>
          </Row.Col>
          <Row.Col alignContent="center" size={6}>
            <Row justify="center" gap={0.15}>
              {documents.map((document) => {
                return (
                  <Row.Col key={document.docId}>
                    <Icon key={document.docId} icon="step-circle" />
                  </Row.Col>
                );
              })}
              <Row.Col>
                <Icon icon="current-step-circle" />
              </Row.Col>
            </Row>
            <Row justify="center">
              <Row.Col>
                <Text variant={TextVariant.h4}>
                  Step {documents.length + 1} of {documents.length + 1}
                </Text>
              </Row.Col>
            </Row>
          </Row.Col>
          <Row.Col size={3}>
            <div></div>
          </Row.Col>
        </ButtonRow>
      </Content>
      <SendReportModal
        selectedEntryId={entryId as string}
        showSendReportModal={showSendReportModal}
        setShowSendReportModal={setShowSendReportModal}
        onConfirm={async () => {
          const route = redirectPath || '/my-work';
          navigate(route);
        }}
      />
    </>
  );
};

export default IncomeCalculationPage;
