// Vendor
import { FC } from 'react';

// Components
import TableCell from 'src/components/atoms/TableCell';
import TableRow from 'src/components/atoms/TableRow';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';

// Helpers

// Constants

// Types
import { Row } from 'src/components/atoms/Row';
import Tag from 'src/components/atoms/Tag';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import ButtonIcon, { ButtonIconBackgroundColor } from 'src/components/molecules/ButtonIcon';
import {
  getDocumentReviewResultStatusColorTag,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';
import { getShortenedGuidForDisplay } from 'src/helpers';
import { useFeatureFlag } from 'src/hooks';
import { FeatureFlagKey } from 'src/ts/enums';
import { DocumentReviewTableRowProps } from '../types';

const DocumentReviewTableRow: FC<DocumentReviewTableRowProps> = ({
  documentReview,
  onDownload
}) => {
  const epaDocumentDownload = useFeatureFlag(FeatureFlagKey.EPA_DOCUMENT_DOWNLOAD);

  return (
    <>
      <TableRow>
        <TableCell />
        <TableCell>
          <Text variant={TextVariant.small}>
            <ToolTip content={documentReview.document_id} direction={ToolTipDirection.right}>
              {getShortenedGuidForDisplay(documentReview.document_id, 13)}
            </ToolTip>
          </Text>
        </TableCell>
        <TableCell textAlign="center">
          <Tag
            label={transformResultToReadable(documentReview.result)}
            color={getDocumentReviewResultStatusColorTag(documentReview.result)}
            showBackground
          />
        </TableCell>
        <TableCell textAlign="center">
          <Text variant={TextVariant.small} align={TextAlign.center}>
            Connected Payroll
          </Text>
        </TableCell>
        <TableCell textAlign="right">
          {epaDocumentDownload ? (
            <Row justify="flex-end" alignItems="center" gap={1}>
              <ButtonIcon
                name="button-download"
                icon="download"
                background={ButtonIconBackgroundColor.none}
                iconColor="primary"
                onClick={() => onDownload?.({ id: documentReview.document_id })}
              />

              <ButtonIcon
                name="button-arrow"
                style={{ visibility: 'hidden' }}
                icon={`keyboard-arrow-down`}
                background={'primary'}
                onClick={() => {
                  // this button is hidden, just here for alignment
                }}
              />
            </Row>
          ) : undefined}
        </TableCell>
      </TableRow>
    </>
  );
};

export default DocumentReviewTableRow;
